<template>
    <div class="page">
        <div class="page-title flex justify-between bg-white">
            <h1 class=" typography-h1 title">
                Lokace
            </h1>
            <q-toggle v-if="showArchivedToggle" v-model="listArchived" class="q-mx-lg my-toggle" left-label :label="$t('label.show-archived')" />
        </div>
        <div class="wrapper">
            <div class="row">
                <div class="col-4 q-pr-md discounts-wrapper">
                    <q-btn
                        v-if="!createMode|| active || viewMode"
                        @click="showCreateCard"
                        class="my-btn create-card"
                        style="width: 100%"
                        label="Přidat lokace"
                        type="text"
                        color="black"
                        icon-right="svguse:/icons.svg#plus2px"
                    />
                    <CardVenue class="skeleton" @delete="createMode=false" v-if="createMode && !active"/>
                    <div class="q-mb-md"></div>
                    <div
                        v-if="filteredVenues.length"
                        v-for="venue in filteredVenues"
                        :key="venue.id"
                        class="q-mb-md">
                        <CardVenue
                            :class="{active: active && active.id === venue.id}"
                            @click.native="setActive(venue)"
                            :id="venue.id"
                            @delete="del"
                            :title="venue.name ? venue.name.cs : ''"/>
                    </div>
                </div>
                <div class="col-8">
                    <div v-if="filteredVenues.length && !createMode && !viewMode"  class="empty">
                        <q-icon name="svguse:/icons.svg#empty" size="48px" class="block q-mb-md"/>
                        <span class="text-grey-6">{{$t('label.select-location')}}</span>
                    </div>
                    <div v-if="!filteredVenues.length && !createMode && !viewMode"  class="empty">
                        <q-icon name="svguse:/icons.svg#empty" size="48px" class="block q-mb-md"/>
                        <span class="text-grey-6">{{$t('label.empty-location')}}</span>
                    </div>
                    <CardVenueCreate @close="closeEdit" @create="createVenue" v-if="createMode && !viewMode && !active"/>
                    <CardVenueCreate @close="closeEdit" :content="active" @create="updateVenue" v-if="viewMode && active"/>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardVenue from "../components/Card/CardVenue/CardVenue";
import CardVenueCreate from "../components/Card/CardVenue/CardVenueCreate";
export default {
    components: {CardVenueCreate, CardVenue},
    @cancellableFetch
    async created() {
        await this.fetchAll('settings');
        await this.fetchMany('venues');
    },

    meta: {
        title: 'Lokace',
    },
    data() {
        return {
            listArchived: false,
            createMode: false,
            active: null,
            viewMode: false,
        };
    },
    computed:{
        filteredVenues() {
            return (this.venues || []).filter(g => this.listArchived ? g.archived : !g.archived).reverse();
        },
    },
    methods:{
        setActive(item) {
            this.createMode = false
            this.viewMode = true;
            this.active = null
            setTimeout(()=>{
                this.active = item
                this.viewMode = true
            }, 0)
        },
        async updateVenue(item){
            const transformed = {
                ...item.original,
                name: item.name,
                address: item.address,
            }
            const venue = await this.update('venues', transformed);
            this.updateIn(this.venues, venue);
            notifyOk(this.$t('notification.general_information-updated'));
            this.active=venue

        },
        async createVenue(item) {
            const transformed = {
                name: item.name,
                address: item.address,
            }
            const venue = await this.create('venues', transformed);
            this.venues.push(venue);
            notifyOk(this.$t('notification.location_saved'));
            this.active=venue
            this.createMode = false
            this.viewMode = true;

        },
        async del(venue) {
            const {result, archived} = await this.delete('venues', venue);
            if (result === 'destroyed') {
                notifyOk(this.$t('notification.location_deleted'));
                this.deleteIn(this.venues, venue);
                this.closeEdit()

            } else if (result === 'archived') {
                notifyOk("Sleva byla archivována.");
                venue.archived = archived;
                this.updateIn(this.venues, venue);
                this.closeEdit()

            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }
        },
        closeEdit() {
            this.active = null
            this.createMode = false
            this.viewMode = false;
        },
        showCreateCard(){
            this.active = null
            this.createMode = false
            this.viewMode = false;
            this.active = null;
            setTimeout(()=>{
                this.createMode = true
            }, 0)
        },
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    }

};
</script>

<style scoped lang="scss">
.page-title{
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid $black-200;
    align-items: center;
    padding: 24px 16px;
}
.wrapper{
    overflow: hidden;
    position: relative;
    padding: 32px 16px 0;
}
.discounts-wrapper {
    height: calc(100vh -  137px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
}
.empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 188px;
    width: 100%;
    border: 1px solid #BABABA;
}
.create-card, .skeleton{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
}
</style>
